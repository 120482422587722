import * as s from './ArHomepagePoweringTiles.module.scss'

import { TRichText, parseRichText } from 'src/utils/parseRichText'

import { ArContainer } from 'src/components/ArContainer'
import React from 'react'
import SvgDocumentSign from 'src/assets/svg/document-sign.svg'
import SvgTrianglesArrowDown from 'src/assets/svg/triangles-arrow-down.svg'
import SvgTrianglesGraph from 'src/assets/svg/triangles-graph.svg'
import { TArHomepagePoweringTilesProps } from './ArHomepagePoweringTiles.types'

const TILES: {
  title: string
  subtitle: TRichText
  icon: any
}[] = [
  {
    title: 'Write more business',
    subtitle: ['Underwrite up to ', { type: 'strong', value: '8x faster' }, ' and free up time for high-value tasks.'],
    icon: <SvgDocumentSign />,
  },
  {
    title: 'Improve performance',
    subtitle: [
      'Enhance risk selection and ',
      { type: 'strong', value: 'reduce loss ratios' },
      ' with more accurate data.',
    ],
    icon: <SvgTrianglesGraph />,
  },
  {
    title: 'Reduce costs',
    subtitle: ['Cut the cost of processing complex submissions ', { type: 'strong', value: 'by 90%.' }],
    icon: <SvgTrianglesArrowDown />,
  },
]

export const ArHomepagePoweringTiles: React.FC<TArHomepagePoweringTilesProps> = () => {
  return (
    <ArContainer className={s.container} contentWrapperClassName={s.contentWrapper} full>
      <h2>Take your portfolio further, faster</h2>
      <p>Artificial drives maximum growth and efficiency for your business.</p>
      <div className={s.tiles}>
        {TILES.map((item, i) => (
          <div key={i} className={s.tile}>
            {item.icon}
            <h3>{item.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: parseRichText(item.subtitle) }} />
          </div>
        ))}
      </div>
    </ArContainer>
  )
}
