import * as s from './ArArticleAuthors.module.scss'

import React from 'react'
import { TArArticleAuthorsProps } from './ArArticleAuthors.types'
import classNames from 'classnames'

export const ArArticleAuthors: React.FC<TArArticleAuthorsProps> = ({ authors, date, className, style }) => {
  const d = new Date(date)
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)

  return (
    <div className={classNames(s.container, className)} style={style}>
      <div className={s.author}>
        <div className={s.photos} style={{ width: 36 + 22 * (authors.length - 1) }}>
          {authors.map((item, i) => (
            <img key={i} src={item.image} alt={item.name} />
          ))}
        </div>
        <div className={s.details}>
          <span>{authors.map(({ name }) => name).join(', ')}</span>
          <span>{`${da} ${mo} ${ye}`}</span>{' '}
        </div>
      </div>
    </div>
  )
}
