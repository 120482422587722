import * as s from './ArArticleTile.module.scss'

import { ArArticleAuthors } from 'src/components/ArArticleAuthors'
import { Link } from 'gatsby'
import { ROUTES } from 'src/config/routes'
import React from 'react'
import { TArArticleTileProps } from './ArArticleTile.types'
import classNames from 'classnames'

export const ArArticleTile: React.FC<TArArticleTileProps> = ({ data: article, theme, className, style }) => {
  const categories = (
    <div className={s.categories}>
      <span>{article.isPressRelease ? 'Press' : 'Article'}</span>
      {' • '}
      {article.category?.map((cat, index) => (
        <React.Fragment key={index}>
          <span key={index}>{cat?.title}</span> {index !== article.category!.length - 1 && ' • '}
        </React.Fragment>
      ))}
    </div>
  )

  const authors = (article.author || []).map((item) => ({
    name: item?.name || '',
    image: item?.profilePhoto?.file?.url || '',
  }))

  return (
    <Link to={ROUTES.blogPost(article.slug as string)}>
      <div
        className={classNames(s.article, className, {
          [s.themeWhite]: theme === 'white',
          [s.themeGray]: theme === 'gray',
        })}
        style={style}>
        <div>
          {categories}
          <h4>{article.title?.title}</h4>
          <p>{article.description?.description}</p>
        </div>
        <ArArticleAuthors className={s.authors} authors={authors} date={article.date} />
      </div>
    </Link>
  )
}
