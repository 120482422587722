export type TDevicesValue<T> = {
  mobile: T
  desktop: T
}

export function getDeviceValue<T>(data: TDevicesValue<T>, isMobile?: boolean): T {
  if (isMobile) {
    return data.mobile
  }
  return data.desktop
}
