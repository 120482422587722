import OgCard from 'src/assets/images/OgCard.png'
import { ROUTES } from 'src/config/routes'

export const SEO_COMMON = {
  image: OgCard,
  site_name: 'artificial.',
  type: 'website',
  twitterName: '@artificiallabs',
}

export const SEO = {
  [ROUTES.homepage()]: {
    title: 'Write better risks, faster - artificial.',
    description: 'Powering the next generation of commercial insurer.',
    url: 'https://artificial.io/',
    ...SEO_COMMON,
  },
  [ROUTES.platform()]: {
    title: 'An all-in-one platform for the insurer of the future - artificial.',
    description:
      'Artificial makes the complex underwriting process frictionless with one powerful, algorithmic platform.',
    url: 'https://artificial.io/underwriting-platform',
    ...SEO_COMMON,
  },
  [ROUTES.brokers()]: {
    title: 'Next generation broking tools - artificial.',
    description: "Experience the future of broking and build MRCv3 contracts with Artificial's Contract Builder.",
    url: 'https://artificial.io/brokers',
    ...SEO_COMMON,
  },
  [ROUTES.about()]: {
    title: 'About us - artificial.',
    description: 'At Artificial, we’re using the latest technology to give insurers power over their data.',
    url: 'https://artificial.io/about',
    ...SEO_COMMON,
  },
  [ROUTES.careers()]: {
    title: 'Careers - artificial.',
    description: 'At Artificial, we’re using the latest technology to give insurers power over their data.',
    url: 'https://artificial.io/careers',
    ...SEO_COMMON,
  },
  [ROUTES.blog()]: {
    title: 'Blog - artificial.',
    description: 'The latest insurtech news, insights and opinion from Artificial.',
    url: 'https://artificial.io/company/blog',
    ...SEO_COMMON,
  },
  [ROUTES.security()]: {
    title: 'Security - artificial.',
    description: 'Information about Artificial security, monitoring and encryption standards.',
    url: 'https://artificial.io/security',
    ...SEO_COMMON,
  },
  [ROUTES.eula()]: {
    title: 'EULA - artificial.',
    description: 'Information about Artificial EULA and terms of use.',
    url: 'https://artificial.io/eula',
    ...SEO_COMMON,
  },
  [ROUTES.privacyPolicy()]: {
    title: 'Privacy - artificial.',
    description: 'Information about Artificial privacy and data collection standards.',
    url: 'https://artificial.io/privacy',
    ...SEO_COMMON,
  },
  [ROUTES.contact()]: {
    title: 'Contact us - artificial.',
    description: 'Contact us to arrange a demo or speak to our team of experts.',
    url: 'https://artificial.io/contact',
    ...SEO_COMMON,
  },
}
