import { SEO, SEO_COMMON } from 'src/config/seo'

import { Helmet } from 'react-helmet'
import React from 'react'
import { TArSeoProps } from './ArSeo.types'

export const ArSeo: React.FC<TArSeoProps> = ({ route, post, image }) => {
  const data = post
    ? {
        title: post.title?.title || 'Blog - artificial.',
        description: post.description?.description || '',
        url: 'https://artificial.io/',
        author: post.author?.join(' ') || null,
        ...SEO_COMMON,
      }
    : SEO[route!]

  const author =
    post?.author
      ?.map((item) => item?.name || '')
      .filter((item) => item.length)
      .join(', ') || null

  return (
    <Helmet>
      <title>{data.title}</title>
      <meta name="description" content={data.description} />

      {/* {keywords.length ? <meta name="keywords" content={keywords.join(',')}/> : null} */}

      <meta itemProp="description" content={data.description} />
      <meta itemProp="image" content={image || data.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={data.title} />
      <meta name="twitter:description" content={data.description} />
      <meta name="twitter:site" content={data.twitterName} />
      <meta name="twitter:domain" content={data.url} />
      <meta name="twitter:url" content={data.url} />
      <meta name="twitter:image" content={image || data.image} />

      <meta property="og:title" content={data.title} />
      <meta property="og:description" content={data.description} />
      <meta property="og:image" content={image || data.image} />
      <meta property="og:url" content={data.url} />
      <meta property="og:site_name" content={data.site_name} />
      <meta property="og:type" content={data.type} />

      <meta property="fb:admins" content="223891341125601" />

      {author && <meta property="og:author" content={author} />}
      {author && <meta name="article:author" content={author} />}
      {/* {tag && <meta name="article:tag" content={tag} />} */}
    </Helmet>
  )
}
