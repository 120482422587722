import * as s from './ArThreeFeaturedArticles.module.scss'

import { ArArticleTile } from 'src/components/ArArticleTile'
import { ArContainer } from 'src/components/ArContainer'
import { Link } from 'gatsby'
import { ROUTES } from 'src/config/routes'
import React from 'react'
import SvgArrowRight2 from 'src/assets/svg/arrow-right-2.svg'
import { TArThreeFeaturedArticlesProps } from './ArThreeFeaturedArticles.types'
import classNames from 'classnames'

export const ArThreeFeaturedArticles: React.FC<TArThreeFeaturedArticlesProps> = ({ title, articles, theme }) => {
  return (
    <ArContainer
      full
      className={classNames(s.container, {
        [s.themeWhite]: theme === 'white',
        [s.themeGray]: theme === 'gray',
      })}
      contentWrapperClassName={s.contentWrapper}>
      <h2>{title}</h2>
      <div className={s.articles}>
        {articles.map((item) => (
          <ArArticleTile key={item.node.id} theme={theme} data={item.node} />
        ))}
      </div>
      <Link to={ROUTES.blog()} className={s.blogLink}>
        View our blog →
      </Link>
    </ArContainer>
  )
}
