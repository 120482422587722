type TTextType = 'strong' | 'italic' | 'br' | 'p' | 'span' | 'list'

export type TRichText = (
  | string
  | { type: Exclude<TTextType, 'list'>; value?: string }
  | { type: 'list'; value: (string | TRichText)[] }
)[]

export function parseRichText(data: TRichText) {
  let result = ''

  data.forEach((item) => {
    if (typeof item === 'string') {
      result += item
    } else if (item.type) {
      switch (item.type) {
        case 'br':
          result += `<br/>`
          break
        case 'p':
          result += `<p>${item.value}</p>`
          break
        case 'strong':
          result += `<strong>${item.value}</strong>`
          break
        case 'span':
          result += `<span>${item.value}</span>`
          break
        case 'italic':
          result += `<i>${item.value}</i>`
          break
        case 'list':
          result += `
              <ul>
              ${item.value.map((data) => `<li>${typeof data !== 'string' ? parseRichText(data) : data}</li>`).join('')}
              </ul>
            `
      }
    }
  })

  return result
}
