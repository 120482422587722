import * as s from './ArHomepagePlatformSelect.module.scss'

import { ArContainer } from 'src/components/ArContainer'
import { Link } from 'gatsby'
import { PLATFROM_SECTIONS } from 'src/config/platformSections'
import { ROUTES } from 'src/config/routes'

import UWPlatformIcon from 'src/assets/images/uw-platform-icon.png'
import ContractBuilderIcon from 'src/assets/images/contract-builder-icon.png'

import React from 'react'
import { TArHomepagePlatformSelectProps } from './ArHomepagePlatformSelect.types'

export const ArHomepagePlatformSelect: React.FC<TArHomepagePlatformSelectProps> = () => {
  return (
    <ArContainer className={s.container} contentWrapperClassName={s.contentWrapper} full>
      <h2>Our products</h2>
      <p className="max-w-md lg:text-center pb-12">
        Our next generation insurance products are propelling commercial insurance forward.
      </p>
      <div className={`grid lg:grid-cols-2 gap-12 w-full ${s.platforms}`}>
        <Link to={ROUTES.platform()} key="insurers">
          <div className="bg-white p-8 rounded-2xl w-full group hover:shadow-2xl cursor-pointer transition-all duration-300">
            <div className="w-56">
              <img src={UWPlatformIcon} alt="Artificial Partner Logos" className="w-1/2 scale-50 pb-12 object-cover" />
            </div>
            <h3>Underwriting Platform</h3>
            <p className="text-base">We give you the power to harness your data and write better risks, faster.</p>
            <button className="text-black bg-white border font-medium border-gray-200 shadow-sm rounded-full px-5 py-1.5 mt-4 group-hover:text-red-500 transition duration-500">
              Explore<span className="ml-1.5 text-red-400">→</span>
            </button>
          </div>
        </Link>
        <Link to={ROUTES.brokers()} key="insurers">
          <div className="bg-white p-8 rounded-2xl w-full group hover:shadow-2xl cursor-pointer transition-all duration-300">
            <div className="w-56">
              <img
                src={ContractBuilderIcon}
                alt="Artificial Partner Logos"
                className="w-1/2 scale-50 pb-12 object-cover"
              />
            </div>
            <h3>Contract Builder</h3>
            <p>Say goodbye to complex data entry and hello to flexible, digital and compliant contracts.</p>
            <button className="text-black bg-white border font-medium border-gray-200 shadow-sm rounded-full px-5 py-1.5 mt-4 group-hover:text-red-500 transition duration-500">
              Explore<span className="ml-1.5 text-red-400">→</span>
            </button>
          </div>
        </Link>
      </div>
    </ArContainer>
  )
}
