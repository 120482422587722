import * as s from './ArHomepageLogos.module.scss'

import { ArContainer } from 'src/components/ArContainer'
import LogoAon from 'src/assets/svg/logo-aon.svg'
import LogoAxis from 'src/assets/svg/logo-axis.svg'
import LogoChaucer from 'src/assets/svg/logo-chaucer.svg'
import LogoConvex from 'src/assets/svg/logo-convex.svg'
import LogoTokioMarine from 'src/assets/images/LogoTokioMarine.png'
import React from 'react'
import { TArHomepageLogosProps } from './ArHomepageLogos.types'

export const ArHomepageLogos: React.FC<TArHomepageLogosProps> = () => {
  return (
    <ArContainer className={s.container}>
      <h6>TRUSTED BY some of THE WORLD’S BEST INSURANCE COMPANIES</h6>
      <div className={s.logos}>
        <div>
          <LogoAxis />
        </div>
        <div>
          <LogoChaucer />
        </div>
        <div>
          <LogoConvex />
        </div>
        <div>
          <LogoAon />
        </div>
        <div>
          <img src={LogoTokioMarine} alt="Tokio Marine" />
        </div>
      </div>
    </ArContainer>
  )
}
