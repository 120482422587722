import * as s from './ArHomepageScreenshot.module.scss'

import HomepageScreenshot from 'src/assets/images/HomepageScreenshot.png'
import React from 'react'
import { TArHomepageScreenshotProps } from './ArHomepageScreenshot.types'
import { getDeviceValue } from 'src/utils/getDeviceValue'
import useDeviceDetect from 'src/utils/useDeviceDetect'

const TRANSFORM_TOP = {
  mobile: 85,
  desktop: 152,
}

export const ArHomepageScreenshot: React.FC<TArHomepageScreenshotProps> = ({ entryAnimation }) => {
  const { isMobile } = useDeviceDetect()

  return (
    <div
      className={s.container}
      style={{
        ['--transform-top' as string]: getDeviceValue(TRANSFORM_TOP, isMobile) + 'px',
      }}>
      {entryAnimation && <div className={s.entryAnimationOverlayer}></div>}
      <div className={s.imageWrapper}>
        <img src={HomepageScreenshot} alt="" width="100%" height="auto" />
      </div>
    </div>
  )
}
